:root {
  font-size: 1rem;
  height: 100%;
}

*:not(input):not(textarea) {
  user-select: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tab-highlight-color: rgba(0, 0, 0, 0);
  -o-tab-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.023rem;
  line-height: 1.255;
}

h1,
h1,
h3,
h4,
h5,
h6 {
  font-family: 'Mulish', sans-serif;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: inside;
}

html,
body,
#root {
  background: #fff;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-size: calc(13px + 6 * ((100vw - 300px) / 600));
  /* base for fluidy - 14px */
}

@media screen and (min-width: 500px) {
  body {
    position: relative;
    width: 500px;
    margin: 0 auto;
  }
  html,
  body,
  #root {
    font-size: 1rem;
    background: #14182e;
  }
}

/* quick nav defaults overrides */

.ui.menu .active,
.ui.menu .active.item:hover,
a.item:active,
a.item:hover {
  background-color: #fff !important;
}

.ui.menu {
  box-shadow: none !important;
}

.ui.card {
  background: #ffffff;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.07);
  padding: 10px;
  margin: 10px !important;
  width: calc(100% - 20px) !important;
  border-radius: 16px;
}

.ui.primary.button,
.ui.primary.buttons .button,
.ui.primary.button:hover,
.ui.primary.buttons .button:hover,
.ui.primary.button:active,
.ui.primary.buttons .button:active {
  padding: 20px;
  margin: 10px;
  width: calc(100% - 20px) !important;
  border-radius: 16px;
  background: #000 !important;
  color: #fff !important;
  cursor: pointer;
}

label {
  padding: 0.3em;
  display: inline-block;
}

.ui.progress {
  margin: 20px 0 !important;
}

#faucet-button {
  background-color: #fff !important;
  border: 1px solid #000;
  color: #000 !important;
}

.p2p-method-detail-account {
  font-size: 0.875rem;
  pointer-events: all;
  cursor: pointer;
}
.p2p-method-detail-phone {
  pointer-events: all;
  cursor: pointer;
}

.p2p-show-details {
  font-size: 0.875rem;
  color: #787878;
  cursor: pointer;
}

.dots-bars-2 {
  margin-top: 16px;
  width: 40px;
  height: 18px;
  --c: linear-gradient(currentColor 0 0);
  --r1: radial-gradient(farthest-side at bottom, currentColor 93%, #0000);
  --r2: radial-gradient(farthest-side at top, currentColor 93%, #0000);
  background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2), var(--c), var(--r1),
    var(--r2);
  background-repeat: no-repeat;
  animation: db2 1s infinite alternate;
}

.gg-clipboard {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  transform: scale(0.55);
  width: 18px;
  height: 18px;
  border: 2px solid;
  border-radius: 2px;
  top: 2px;
  left: 2px;
}
.gg-clipboard::after,
.gg-clipboard::before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 2px;
  width: 10px;
  left: 2px;
}
.gg-clipboard::before {
  border: 2px solid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  top: -2px;
  height: 6px;
}
.gg-clipboard::after {
  height: 2px;
  background: currentColor;
  box-shadow: 0 -4px 0 0;
  bottom: 2px;
}

@keyframes db2 {
  0%,
  25% {
    background-size: 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(50% - 2px), 0 calc(50% + 2px), 50% 50%, 50% calc(50% - 2px),
      50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
  }
  50% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(50% - 2px),
      50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
  }
  75% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(0% - 2px),
      50% calc(100% + 2px), 100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
  }
  95%,
  100% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px,
      8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%, 50% calc(0% - 2px),
      50% calc(100% + 2px), 100% 50%, 100% calc(0% - 2px), 100% calc(100% + 2px);
  }
}

.p2p-offer-item-enter {
  opacity: 0;
}
.p2p-offer-item-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.p2p-offer-item-exit {
  opacity: 1;
}
.p2p-offer-item-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
